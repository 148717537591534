.inputContainer {
    position: absolute;
    z-index: 1;
    margin: 10px;
    top: 11.5rem;
}

.search-container {
    position: absolute;
    z-index: 1;
    margin: 10px;
}

.rounded-input1 {
    border: 2px solid #ccc;
    border-radius: 12px;
    padding: 10px;
    width: 180px;
    font-size: 16px;

}

.rounded-input2 {
    border: 2px solid #ccc;
    border-radius: 12px;
    padding: 10px;
    width: 94px;
    font-size: 16px;

}

.fetch-last {
    display: flex;
    align-items: center;

}

.fetch-last-row {
    display: flex;
    align-items: center;
    margin: 5px;
}

label {
    float: left;
}

span {
    display: block;
    overflow: hidden;
    padding: 0px 4px 0px 6px;
}

.fetch-txt {
    color: black;
    font-weight: 600;
}

.lokiId-input {
    display: flex;
    align-items: center;
}

.add-btn {
    left: 4rem;
    font-size: 25px;
    color: #337c82ff;
}

.remove-btn {
    left: 4rem;
    font-size: 25px;
    color: #337c82ff;
}

.navigation-btn{
    border: none;
    background: transparent;
    color: #337c82ff;
    font-size: 25px;
    padding: 0;
}

.fetchX{
    top: -1px;
}
