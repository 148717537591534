.link-color {
  color: #007bff;
  cursor: pointer;
}

.promocode-table .table-row-content {
  cursor: default;
}

.table-row-content .no-data {
  display: flex;
  align-items: center;
  height: 260px;
  justify-content: center;
  font-size: 14px;
}

.search-wrapper .search-bar-text-wrap {
  width: 100%;
}

.promocode .card-span {
  display: block;
  line-height: 1.4;
}

.promo-card-title {
  font-size: 25px;
  font-weight: 700;
  margin-left: 10px;
}

.promo-card-name {
  font-size: 14px;
  margin-left: 12px;
}

.promo-card-description {
  font-size: 14px;
  margin-left: 12px;
}

.subscriber-list .subscriber-list-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 180px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #007FA1;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
}

.subscriber-list .subscriber-list-btn:hover {
  border: 1px solid #007FA1;
}

.membership-table-wrapper {
  margin-top: 10px;
}

.page-btn {
  align-items: center;
  min-width: 100px;
  height: 25px;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #007FA1;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  margin-right: 10px;
}

.dashboard .customers-card .card-icon {
  width: 84px;
  height: 84px;
}

.top {
  top: -1.8em;
}

.ok-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 50px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #007FA1;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
}

.for-ok-btn {
  left: 35rem;
}