.ms-auto {
  margin-left: auto !important;
}

.sidebar-nav .nav-content .nav-content-item:hover,
.sidebar-nav .nav-content .nav-content-item.active {
  color: #4154f1;
}
.sidebar-nav .nav-content .nav-content-item:hover i {
  background-color: #4154f1;
}
.sidebar-nav .nav-content .nav-content-item.active i {
  background-color: #4154f1;
}

.nav-content-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
  cursor: pointer;
}

.nav-content-item i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.modal-confirm{
  z-index: 9999;
}

.modal-confirm .modal-confirm-body{
  right: 0 !important;
}
