.dropdown-menu.notifications, .dropdown-menu.profile {
    display: block;
    transform: translate(-25px, 35px);
}
.dropdown-menu.profile {
    transform: translate(-96px, 38px);
}

.header-nav .nav-item {
    cursor: pointer;
}

.header {
    padding-right: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
