.event-popup {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - 212px);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-popup:after {
  content: '';
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.event-popup .event-popup-content {
  align-items: flex-start;
  background: #fff;
  border: 1px solid #d6d8d9;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  padding: 15px 25px;
  position: relative;
  width: auto;
  min-width: 400px;
  z-index: 3;
}

.event-popup .event-popup-content .popup-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.event-popup .event-popup-content .popup-title .title {
  font-size: 18px;
  font-weight: 700;
  color: #012970;
}

.event-popup .event-popup-content .close {
  cursor: pointer;
}

.event-popup .event-popup-content .detail-data {
  display: table-column;
  width: 100%;
  border-top: 1px solid #0DA9B9;
  padding-top: 15px;
  margin-top: 15px;
}

.event-popup .event-popup-content .detail-data .label {
  font-weight: 700;
  color: #000;
  font-size: 14px;
}
