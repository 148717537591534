i {
  cursor: pointer;
}

.social-links {
  gap: 10px;
}

.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #4154f1;
  border-bottom: 2px solid #4154f1;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: #4154f1;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: none;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  cursor: pointer;
}