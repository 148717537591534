.input-style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 3px;
  padding: 0 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-style: solid;
  border-width: 0.5px;
  width: 100%;
}

input:focus-visible {
  outline: 1px solid #007FA1;
}

.input-filled {
  padding-top: 10px;
  transition: 0.2s ease all;
}

.input-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  height: inherit;
}

.input-container .filled {
  position: absolute;
  top: 5px;
  pointer-events: none;
  left: 15px;
  transition: 0.2s ease all;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}

.input-container .filled .filled-label {
  color: #007FA1;
  font-size: 12;
}

.input-container .input-style::placeholder {
  font-weight: 400;
  font-size: 15px;
  color: #626566;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  font-size: 10px;
  line-height: 1;
  left: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
}

.input-container input {
  border-radius: 4px;
  border: none;
  font-size: 15px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.input-container .close-icon {
  cursor: pointer;
  margin-right: 5px;
}

.input-labelled-wrap {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #9DD9E0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  justify-content: center;
}

.first-row .input-labelled-wrap {
  padding-left: 7px;
  justify-content: left;
}

.search-bar {
  min-width: 360px;
}

.search-bar .search-form {
  width: 100%;
  position: relative;
}

.search-bar .search-form input {
  font-size: 14px;
  color: #012970;
  border: 1px solid #aab7b8;
  padding: 0.4rem 2rem;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}
.search-bar .search-form button {
  border: 0;
  padding: 0;
  background: none;
  position: absolute;
  left: 0.75rem;
  top: 50%;
  margin-top: -13px;
}
.search-bar .search-form button i {
  color: #012970
}
