.inspector {
  position: relative;
  z-index: 997;
}

.loader-image {
  position: absolute;
  top: 60%;
  margin-top: -37px;
  left: 50%;
  margin-left: -37px;
}

.loader-image img {
  width: 75px;
}

.inspector .card {
  margin-bottom: 0;
}

.inspector .card.card-table {
  min-height: calc(100vh - 250px);
}

.inspector .card.card-table .card-body {
  padding: 1.1rem;
}

.inspector .card-table .table {
  width: 100%;
  color: #212529;
  margin-bottom: 0;
}

.inspector .card-table .table .table-content {
  overflow-y: auto;
  max-height: calc(100vh - 370px);
}

.table-row-content {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px 8px 15px;
  border: 1px solid #D6D8D9;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 2px;
}

.table-row-content.child-content {
  border-left: 5px solid #abb1b2;
  border-radius: 3px 0 0 3px;
  margin-left: 8px;
}

.table-row-content.tr-active {
  background: #f2f9fa;
  border: 1px solid #0da9b9;
  color: #000;
}

.inspector .card.card-table .no-data {
  display: flex;
  align-items: center;
  height: 260px;
  justify-content: center;
  font-size: 14px;
}

.table-row-content .detail-options {
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  border-radius: 4px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
  background: #007FA1;
  padding: 5px;
  width: 80px;
}

.card-title-group {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
}

.card-title-group .subscriber-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 180px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #007FA1;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
}

.card-title-group .subscriber-detail:hover {
  border: 1px solid #0DA9B9;
}

button:disabled {
  pointer-events: none;
  opacity: 0.75;
}