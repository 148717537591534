.first-aid-items .card-title-group,
.emergency-numbers .card-title-group {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  flex-direction: column;
}

.first-aid-items .card.card-table .card-body {
  padding: 1.1rem;
}

.first-aid-items .card.card-table .card-body .card-title-group .card-title {
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 700;
}

.first-aid-items .seed-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 180px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #007FA1;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
}

.first-aid-items .seed-data:hover {
  border: 1px solid #007FA1;
}

.first-aid-items .seed-data-group {
  display: flex;
  align-items: center;
  justify-items: flex-start;
  gap: 10px;
}

.first-aid-items .loader-image {
  position: inherit;
  top: 0;
  margin-top: 50px;
  left: 50%;
  margin-left: 0;
}

.first-aid-items .list-group .list-item {
  display: flex;
  border-bottom: 1px solid #9DD9E0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
}

.first-aid-items .list-group .list-item:hover {
  background: #E7F6F8;
  border-radius: 8px;
}

.first-aid-items .list-group .list-item:hover .chevron-container {
  background: #FFFFFF;
}

.first-aid-items .list-group .list-item .chevron-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;
  width: 28px;
  height: 28px;
  background: #E7F6F8;
  border-radius: 32px;
}

.first-aid-items .list-group .list-item.item-active {
  background: #E7F6F8;
  border-radius: 8px;
}

.reference-data-container .card-body {
  border-radius: 0.25rem;
}

.loader-image {
  position: inherit;
  top: 0;
  margin-top: 50px;
  left: 50%;
  margin-left: 0;
}

/* subscriptions */
.subscriptions-container .card.card-table .card-panel-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #e9ecef;
  padding: 0px 15px;
  min-height: 50px;
  color: #000000;
  flex-wrap: wrap;
}

.subscriptions-container .card.card-table .card-panel-body {
  padding: 15px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
}