.first-aid-items .event-popup {
  position: fixed;
  z-index: 100;
  left: 9%;
  top: 5%;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  height: 100%;
}

.nice-admin.toggle-sidebar .first-aid-items .event-popup {
  left: 0%;
}

.first-aid-items .event-popup:after {
  content: '';
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.first-aid-items .event-popup .event-popup-content {
  align-items: flex-start;
  background: #fff;
  border: 1px solid #d6d8d9;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 20%;
  padding: 15px 25px;
  position: relative;
  width: auto;
  min-width: 20%;
  max-width: 50%;
  z-index: 3;
  max-height: 60%;
}

.first-aid-items .event-popup .event-popup-content .popup-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: sticky;
}
.first-aid-items .event-popup .event-popup-content .popup-title .title {
  font-size: 18px;
  font-weight: 700;
  color: #012970;
}

.first-aid-items .event-popup .event-popup-content .close {
  cursor: pointer;
}

.first-aid-items .event-popup .event-popup-content .detail-data {
  display: table-column;
  width: 100%;
  border-top: 1px solid #0DA9B9;
  padding-top: 15px;
  margin-top: 15px;
  overflow: auto;
}

.first-aid-items .event-popup .event-popup-content .detail-data .label {
  font-weight: 700;
  color: #000;
  font-size: 14px;
}
