.search-bar-text-wrap {
  display: flex;
  justify-items: center;
  justify-items: center;
  align-items: center;
  margin-bottom: 20px;
  width: 60%;
}
.search-bar-text-wrap .button-style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  color: white;
  line-height: 24px;
  cursor: pointer;
  transition: 0.5s;
  background-color: rgb(0, 127, 161);
  width: 135px;
  height: 50px;
  margin: 0px 0px 0px 10px;
  font-size: 17px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
}

.search-bar-text-wrap .input-labelled-wrap {
  border-radius: 4px;
}