.modal-confirm {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-confirm .modal-confirm-body {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 0.5s ease-in-out;
  right: -10%;
}

.nice-admin.toggle-sidebar .modal-confirm .modal-confirm-body {
  right: 0%;
}

.modal-confirm .modal-confirm-body .modal-confirm-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #9DD9E0;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.modal-confirm .modal-confirm-body .modal-confirm-header .close {
  cursor: pointer;
}

.modal-confirm .modal-confirm-body .modal-confirm-content {
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.modal-confirm .modal-confirm-body .modal-confirm-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-confirm .modal-confirm-body .modal-confirm-footer button {
  min-width: 180px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #007FA1;
  font-weight: 600;
  font-size: 16px;
}

.modal-confirm .modal-confirm-body .modal-confirm-footer button:hover {
  border: 1px solid #0DA9B9;
}