.sidebar-nav .nav-link {
  cursor: pointer;
}

.scroll-custom::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(235, 214, 214, 0.3);
  border-radius: 8px;
  background-color: #F5F5F5;
}

.scroll-custom::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #F5F5F5;
}

.scroll-custom::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(235, 214, 214, 0.3);
  background-color: #ABB1B2;
}

.nice-admin {
  height: calc(100vh - 60px);
  width: 100%;
  margin: 0;
  padding: 0;
}

.sidebar {
  z-index: 1000;
}