
.accordion-container .tile-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-container .accordion-list {
  border-bottom: 1px solid #9DD9E0;
  min-height: 1rem;
  padding: 1rem;
}

.accordion-container .chevron-container {
  color: #007FA1;
}

.accordion-container .accordion-content {
  padding-left: 0.5rem;
}

.accordion-container .accordion-content .accordion-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion-container .accordion-content .accordion-content-item .text-content {
  display: flex;
  align-items: center;
  gap: 15px
}

.accordion-container .accordion-content .accordion-content-item .icon {
  display: flex;
  gap: 10px
}

.accordion-container .accordion-content .accordion-content-item .bi.bi-pencil-square {
  color: #007FA1;
  pointer-events: none;
  opacity: 0.5;
}

.accordion-container .accordion-content .accordion-content-item .bi.bi-trash {
  color: #FFCBCE;
  pointer-events: none;
}