.link-color {
    color: #007bff;
    cursor: pointer;
}

.promocode-table .table-row-content {
    cursor: default;
}

.promocode-table .no-data {
    display: flex;
    align-items: center;
    height: 260px;
    justify-content: center;
    font-size: 14px;
}

.dropdown-menu.enrollment-other-detail {
    display: block;
}

.enrollment-other-detail {
    padding-bottom: 0;
    top: 8px !important;

}
