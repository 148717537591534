.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
}

.notification-toast {
  display: flex;
  position: relative;
  padding: 1rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  min-width: 250px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.notification-close {
  cursor: pointer;
  margin-left: 5px;
}

.toast-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.toast-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.toast-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.top-right {
  top: 37px;
  right: 15px;
  transition: transform .6s ease-in-out;
  animation: toast-in-right .7s;
}

.bottom-right {
  bottom: 15px;
  right: 15px;
  transition: transform .6s ease-in-out;
  animation: toast-in-right .7s;
}

.top-left {
  top: 15px;
  left: 15px;
  transition: transform .6s ease-in;
  animation: toast-in-left .7s;
}

.bottom-left {
  bottom: 10px;
  left: 10px;
  transition: transform .6s ease-in;
  animation: toast-in-left .7s;
}