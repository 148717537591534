.subscriber-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  display: flex;
  align-items: center;
  justify-content: center;
}


.subscriber-popup .popup-content {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 50%;
  position: relative;
  transition: all 1s ease-in-out;
  right: -10%;
}

.nice-admin.toggle-sidebar .subscriber-popup .popup-content {
  right: 0%;
}

.subscriber-popup .popup-content .popup-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #0DA9B9;
  color: #012970;
}

.subscriber-popup .popup-content .popup-title .title {
  font-size: 18px;
  font-weight: 700;
}

.subscriber-popup .popup-content .close {
  cursor: pointer;
}

.subscriber-popup .popup-content .profile-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}