.date-picker-style {
  width: 100%;
  height: auto;
}

.date-picker-style input {
  font-size: 14px;
  color: #484848;
  border: none;
  padding: 0.5rem;
  border-radius: 3px;
  transition: 0.3s;
  height: 30px;
  width: 416px;
}

/* custom css */
.btn-date-picker-apply {
  background: #ec7211 !important;
  font-size: 12px !important;
  border-radius: 3px !important;
  border: 1px solid !important;
  padding: 5px 10px !important;
  font-weight: bold !important;
  letter-spacing: .25px;
  cursor: pointer;
  color: #FFF !important;
}

.btn-clear-date-picker {
  position: absolute;
  left: 0;
  border: solid 1px #545b64 !important;
  color: #545b64 !important;
}

.range-date-times {
  display: flex;
  align-items: center;
}
.range-date-times .bi.bi-calendar {
  position: absolute;
  right: 30px;
}
.hide-datepicker {
  display: none;
}

.daterangepicker th.month {
  font-size: 16px;
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  font-size: 14px!important;
  height: 38px!important;
  width: 38px!important;
}

.calendar-table tbody td{
  border: 1px solid #e4e7e7!important;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background: #007fa1!important;
  color: #16191f!important;
}
