.search-bar-text-wrap {
  display: flex;
  justify-items: center;
  justify-items: center;
  align-items: center;
  margin-bottom: 20px;
  width: 60%;
}
.search-bar-text-wrap .button-style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  color: white;
  line-height: 24px;
  cursor: pointer;
  transition: 0.5s;
  background-color: rgb(0, 127, 161);
  width: 135px;
  height: 40px;
  margin: 0px 0px 0px 10px;
  font-size: 17px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
}
.input-labelled-wrap {
  height: 40px !important;
}
.search-bar-text-wrap .input-labelled-wrap {
  border-radius: 4px;
}
.is-active {
  color: green;
}

.is-deleted {
  color: red;
}
.card-header {
  height: 50px;
}
.card-box {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.hide-large-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-content.scroll-custom {
  overflow-y: auto;
  max-height: calc(100vh - 370px);
}
.group-container{
  padding: 1.25rem;
}
.icon-layout {
  border: 1px solid #FFECDF;
  background-color: #FFECDF;
  width: 100px;
  border-radius: 50px;
  text-align: center;
  height: 100px;
}
.group-icon {
  font-size: 60px;
  color: #FF7D38;
}
.group-text {
  position: relative;
  top: 20px;
  left: 16px;
}