.daterange-filter button {
    color: #545b64;
    font-weight: 400;
    height: 28px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    border: none;
    background: transparent;
}

.filter-button button:hover, .filter-button button:active {
    background: #fafafa;
}

.filter-button .active {
    background: #aab7b8;
}

.daterange-filter {
    border: 1px solid #aab7b8;
    display: flex;
    align-items: center;
}

.daterange-filter .custom-filter {
    border-left: 1px solid #aab7b8;
}

.daterange-filter .custom-filter i{
    margin-left: 16px;
}

.custom-right-filter {
    flex: 0 0 450px!important;
    max-width: 450px;
}

.custom-left-filter {
    max-width: calc(100% - 450px);
    flex: 0 0 calc(100% - 450px)!important;
}
