.input-label-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  flex-wrap: nowrap;
  margin: 4px 0px;
  width: auto;
}

.input-label-style input {
  color: #2C2E2E;
  font-weight: 600;
  font-size: 15px;
  background: #F5F7F7;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
}

.input-label-style input:disabled {
  border: none;
  background: #FFFFFF;
}
